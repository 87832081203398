// ProgramCard.js
import React from 'react';
import { FiPaperclip } from "react-icons/fi";
import { SiActivitypub } from "react-icons/si";

export default function ProgramCard({ program }) {
  return (
    <div className="tw-max-w-sm tw-w-full md:tw-w-full lg:tw-min-w-full lg:tw-flex tw-shadow-custom tw-rounded-sm">
      <div
        className="tw-h-48 lg:tw-h-auto lg:tw-w-48 tw-flex-none tw-bg-cover tw-rounded-t lg:tw-rounded-t-none lg:tw-rounded-l tw-text-center tw-overflow-hidden"
        style={{ backgroundImage: `url(${program.image})` }}
        title={program.title}
      ></div>

      <div className=" tw-bg-white tw-rounded-b lg:tw-rounded-b-none lg:tw-rounded-r tw-p-4 tw-flex tw-flex-col tw-justify-between tw-leading-normal">
        <div className="tw-mb-8">
          <div className="tw-text-sm tw-text-gray-600 tw-flex tw-items-center tw-mb-2 tw-font-semibold">
            <SiActivitypub className='tw-text-prim-orange' />
            <p className='tw-ml-2'>{program.subtitle}</p>
          </div>
          <h4 className="tw-text-gray-900 tw-font-bold tw-text-xl tw-mb-2">
            {program.title}
          </h4>
          <p className="tw-text-gray-700 tw-text-base">{program.description}</p>
        </div>

        {program.additionalInfo && (
          <div className="tw-text-sm tw-text-gray-600 tw-flex tw-items-center tw-cursor-pointer">
          <a href={program.pageUrl} className="tw-flex tw-items-center hover:tw-text-prim-dark tw-duration-300 tw-ease-in-out">
            <FiPaperclip size={20} className="tw-text-prim-orange tw-mr-2" />
            <span className="tw-text-lg tw-text-prim-orange">Learn More</span> {/* Changed to span */}
          </a>
        </div>
        
        )}
      </div>
    </div>
  );
}
