import React from 'react';
import './partners.css';

export default function Partnershipslider({ logos }) {
  // Duplicate logos array to create a seamless scroll effect
  const duplicatedLogos = [...logos, ...logos].reverse();

  return (
    <div className="tw-flex tw-h-[400px] tw-overflow-hidden tw-justify-end">
      
      {/* First scrolling wheel - slow speed, scrolling downwards */}
      <div className="tw-w-[200px] tw-h-full tw-overflow-hidden tw-px-2">
        <div className="tw-flex tw-flex-col tw-animate-scroll-down-slow">
          {duplicatedLogos.map((logo, index) => (
            <img
              src={logo.logo}
              alt={logo.name}
              key={`slow-${index}`}
              className="tw-h-auto tw-my-2 tw-rounded-md"
            />
          ))}
        </div>
      </div>
      
      {/* Second scrolling wheel - medium speed, scrolling upwards */}
      <div className="tw-w-[200px] tw-h-full tw-overflow-hidden tw-px-2">
        <div className="tw-flex tw-flex-col tw-animate-scroll-up-medium">
          {duplicatedLogos.map((logo, index) => (
            <img
              src={logo.logo}
              alt={logo.name}
              key={`medium-${index}`}
              className="tw-h-auto tw-my-2 tw-rounded-md"
            />
          ))}
        </div>
      </div>

      {/* Third scrolling wheel - fast speed, scrolling downwards */}
      <div className="tw-w-[200px] tw-h-full tw-overflow-hidden tw-px-2">
        <div className="tw-flex tw-flex-col tw-animate-scroll-down-fast">
          {duplicatedLogos.map((logo, index) => (
            <img
              src={logo.logo}
              alt={logo.name}
              key={`fast-${index}`}
              className="tw-h-auto tw-my-2 tw-rounded-md"
            />
          ))}
        </div>
      </div>
      
    </div>
  );
}
