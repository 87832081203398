import { Routes, Route } from "react-router-dom";
import Home from "pages/home/home";
import About from "pages/about/about";
import { AppContextProvider } from "context/appcontext";
import SiteLayout from "components/layouts/sitelayout";
import 'assets/css/App.css';
import TestCarousel from "pages/test/testcarousel";
import OrgStructure from "pages/organization/organization";
import { AboutUsPageLink, AchievementsPageLink, FinancialCapacityStatetmentPageLink, HomePageLink, OrganizationStructurePageLink, PartnersPageLink, ProgrammesPageLink } from "lib/constants";
import Partnerships from "pages/partnerships/partnerships";
import Achievements from "pages/achievements/achievements";
import Programmes from "pages/ourprograms/ourprograms";
import FinancialCapacityStatement from "pages/financialstmt/financialcapacitystatement";

function App() {
  return (
    <>
      <AppContextProvider>
        <SiteLayout>
          <Routes>
            <Route path={HomePageLink} element={<Home />} />
            <Route path={AboutUsPageLink} element={<About />} />
            <Route path={OrganizationStructurePageLink} element={<OrgStructure />} />
            <Route path={AchievementsPageLink} element={<Achievements />} />
            <Route path={PartnersPageLink} element={<Partnerships />} />
            <Route path={ProgrammesPageLink} element={<Programmes />} />
            <Route path={FinancialCapacityStatetmentPageLink} element={<FinancialCapacityStatement />} />

          </Routes>
        </SiteLayout>
      </AppContextProvider>
    </>
  );
}

export default App;
