import React from "react";
import "./org.css";
import ConferenceRoomImage from "assets/images/backgrounds/conference-room.jpg";
import Penina from "assets/images/siteimages/Peninah Mwenda.jpg"
import Nicholas from "assets/images/siteimages/Nicholas Mwenda.png"
import James from "assets/images/siteimages/James Muriithi.png"
import Cyprian from "assets/images/siteimages/Cyprian Amakalu.png"

import { FaPhone } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

export default function OrgStructure() {
  const BoardOfDirectors = () => {
    const directors = [
      {
        name: "Dr. Peninah K. Mwenda",
        profession: "Climate/Environmental Health-Psychology",
        designation: "Director/ Founder/Secretary BOM",
        contact: "0721 679 953",
        email: "pwcion@yahoo.com",
        image: Penina
      },
      {
        name: "Dr. Nicholas M. Jacob",
        profession: "PhD Analytical Chemistry, MA Environmental Chemistry, Bachelor of Education (Science) Chemistry/Mathematics",
        designation: "Director/ Founder",
        contact: "0722 985 262",
        email: "mwendanj@yahoo.com",
        image: Nicholas
      },
      {
        name: "Cyprian Amakalu",
        profession: "Bachelor of Economic/ Accountant",
        designation: "Director/Chairman Board of Management",
        contact: "0733 398 530",
        email: "cmunyakho@gmail.com",
        image: Cyprian
      },
      {
        name: "James Murithi",
        profession: "MA, Electrical Engineering, Bachelor of Science, Electrical Engineering",
        designation: "Director/Treasurer Board of Management",
        contact: "0722 861 711",
        email: "james_murithi@hotmail.com",
        image: James
      },
      {
        name: "Sarah Moraa",
        profession: "Educationist",
        designation: "Member",
        contact: "0722 363 898",
        email: "Sara.muraa@yahoo.com",
        image:""
      },
    ];

    return (
      <div className="tw-grid tw-grid-cols-4 tw-gap-4">
        {directors.map((director, index) => (
          <div key={index} className="tw-p-4 tw-rounded-md">
            {director.image && <div className="tw-h-48 tw-w-48 tw-border">
              <img src={director.image} alt={"test"} className="tw-h-full tw-object-cover tw-w-full" />
            </div>}
            <p className="tw-mb-4 tw-text-2xl tw-font-bold tw-italic">
              {director.designation}
            </p>
            <p className="tw-font-semibold">{director.name}</p>
            <p>{director.profession}</p>
            <div className="tw-flex tw-flex-row tw-items-center">
              <span className="tw-mr-2">
                <FaPhone />
              </span>
              <p>{director.contact}</p>
            </div>
            <div className="tw-flex tw-flex-row tw-items-center">
              <span className="tw-mr-2">
                <MdEmail />
              </span>
              <p>{director.email}</p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const TechnicalStaff = () => {
    const professionals = [
      {
        name: "Dr. Peninah Mwenda",
        profession: "Psychology / Climate Change",
        academicQualification: [
          "PhD Climate Change and Adaptation",
          "MA Counseling Psychology",
          "B.Ed. Geography/ History",
        ],
        contact: {
          address: "P.O Box 945-00502, Nairobi, Kenya.",
          mobile: "0721679953",
        },
      },
      {
        name: "Dr. Nicholas M. Jacob",
        profession: "Environmental Chemist Expert",
        academicQualification: [
          "M.Sc. Environmental Science",
          "PhD. Environment Chemist",
        ],
        contact: {
          address: "P.O Box 945-00502, Nairobi, Kenya.",
          mobile: "0722985262",
        },
      },
      {
        name: "Silas Gituma",
        profession: "Mycologist",
        academicQualification: ["M.Sc. Mycologist"],
        contact: {
          address: "P.O Box 945-00502, Nairobi, Kenya.",
          mobile: "0712646810",
        },
      },
    ];

    return (
      <div className="tw-p-2">
        {professionals.map((professional, index) => (
          <div key={index} className="tw-p-4 tw-rounded-lg">
            <h3 className="tw-text-lg tw-font-bold">{professional.name}</h3>
            <p className="tw-italic">{professional.profession}</p>
            <p className="tw-mb-2">{professional.academicQualification.join(", ")}</p>
            <p>{professional.contact.address}</p>
            <p>{professional.contact.mobile}</p>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="tw-relative">
      <div className="tw-relative tw-h-[50vh]">
        <img
          src={ConferenceRoomImage}
          alt="Background"
          className="tw-object-cover tw-w-full tw-h-full tw-opacity-70"
        />
        <div className="tw-absolute tw-inset-0 tw-bg-black tw-opacity-50" />{" "}
        {/* Overlay */}
        <div className="tw-absolute tw-inset-0 tw-flex md:tw-mx-32 tw-left-10 tw-items-center">
          <div className="md:tw-w-2/3">
            <h5 className="tw-text-white tw-text-3xl md:tw-text-6xl tw-font-bold">
              Our Organization
            </h5>
          </div>
        </div>
      </div>
      <section className="tw-mb-10 tw-p-10 md:tw-mx-32">
        <div className="tw-relative tw-inline-block tw-mb-10 tw-text-prim-purple">
          <h2 className="tw-text-3xl md:tw-text-4xl tw-font-bold tw-mb-4">
            Board Of Directors
          </h2>
          <div className="tw-absolute tw-left-0 tw-bottom-0 tw-w-1/3 tw-h-1 tw-bg-prim-orange tw-mt-2" />
        </div>

        <BoardOfDirectors />
      </section>
      <section className="tw-mb-10 tw-py-10 tw-flex tw-flex-col tw-bg-prim-bg-gray">
        <div className="tw-relative tw-inline-block tw-mb-10 tw-text-prim-dark tw-w-max tw-mx-auto">
          <h2 className="tw-text-xl md:tw-text-2xl tw-font-bold tw-mb-4">
            Organogram
          </h2>
          <div className="tw-absolute tw-left-0 tw-bottom-0 tw-w-1/3 tw-h-1 tw-bg-prim-orange tw-mt-2" />
        </div>

        <div className="tw-px-4 md:tw-px-10 tw-mb-5 tw-justify-center tw-grid tw-grid-cols-2">
          <div className="tree tw-w-max ">
            <ul>
              <li>
                <a href="#">Board of Directors</a>
                <ul>
                  <li>
                    <a href="#">Chief Executive Officer</a>
                    <ul>
                      <li>
                        <a href="#">Administrative Manager</a>
                        <ul>
                          <li>
                            <a href="#">Human Resource</a>
                          </li>
                          <li>
                            <a href="#">Accounting Officer</a>
                          </li>
                          <li>
                            <a href="#">Auditor</a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="#">
                          Communication, Marketing and Dissemination Manager
                        </a>
                        <ul>
                          <li>
                            <a href="#">P.R.O</a>
                          </li>
                          <li>
                            <a href="#">Data Analyst</a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="#">Programmes Manager</a>
                        <ul>
                          <li>
                            <a href="#">Project Assistants</a>
                          </li>
                          <li>
                            <a href="#">Training Assistants</a>
                          </li>
                          <li>
                            <a href="#">Monitoring Assistants</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div>
            <p>
              The structure is composed of the executive management, management,
              the technical staff and the Volunteers
            </p>
            <div className="tw-mt-4">
              <p className="tw-font-bold tw-text-xl">Technical Staff</p>
            </div>
            <TechnicalStaff />
          </div>
        </div>
      </section>
    </div>
  );
}
