import React from "react";
import MoneyImage from "assets/images/backgrounds/money.jpg";

export default function FinancialCapacityStatement() {
  return (
    <div>
      <div className="tw-relative tw-h-[50vh]">
        <img
          src={MoneyImage}
          alt="Background"
          className="tw-object-cover tw-w-full tw-h-full tw-opacity-70"
        />
        <div className="tw-absolute tw-inset-0 tw-bg-black tw-opacity-80" />{" "}
        {/* Overlay */}
        <div className="tw-absolute tw-inset-0 tw-flex md:tw-mx-32 tw-left-10 tw-items-center">
          <div className="md:tw-w-2/3">
            <h5 className="tw-text-white tw-text-3xl md:tw-text-6xl tw-font-bold">
              Financial Capacity<br/> Statement
            </h5>
          </div>
        </div>
      </div>
      <section className="tw-p-10 md:tw-mx-32">
        <div className="tw-flex-1 tw-flex tw-flex-col  tw-p-4 tw-mb-10">
          <div className="tw-relative tw-inline-block tw-mb-5 tw-text-prim-dark tw-w-max">
            <h4 className="tw-text-xl md:tw-text-2xl tw-font-bold tw-mb-4">
              Financial Capacity Statement
            </h4>
            <div className="tw-absolute tw-left-0 tw-bottom-0 tw-w-1/3 tw-h-1 tw-bg-prim-orange tw-mt-2" />
          </div>

          <div className="tw-text-gray-700">
            <p className="tw-mb-3">
              Providence whole care international prepares annual budgets that
              are approved by the Board. PWCI prepares periodic financial
              reports. Monthly reports are prepared for the Chief Executive
              Officer and the Treasurer for submission to the Board. The
              accounts are externally audited. The financial reports also
              compare performance against budget and explanations are sought for
              significant variances. Accounting is in four distinct cycles;
              payments, income/receipts, payroll, and general ledger and
              reporting.
            </p>
            <p  className="tw-mb-3">
              The payments cycle is linked to the Procurement system and the
              Funds Disbursement system. The income cycle is linked to the
              funding partnership development system. Payroll cycle is linked to
              the Human Resources management system. The general ledger and
              reporting cycle is linked to the Donor Reporting system. We are
              capable of generating reports that are compliant to donor
              requirements. There are Internal Control systems that maintain the
              in financial capacity:
            </p>

            <p  className="tw-mb-3">
              Providence Whole Care International prepares annual budgets that
              are approved by the Board. Providence VCT prepares periodic
              financial reports. Monthly reports are prepared for the Chief
              Executive Officer and the Treasurer and for submission to the
              Board. The accounts are externally audited. The financial reports
              also compare performance against budget and explanations are
              sought for significant variances. Accounting is in four distinct
              cycles; payments, income/receipts, payroll, and general ledger and
              reporting. The payments cycle is linked to the Procurement system
              and the Funds Disbursement system. The income cycle is linked to
              the funding partnership development system. Payroll cycle is
              linked to the Human Resources management system. The general
              ledger and reporting cycle is linked to the Donor Reporting
              system. We are capable of generating reports that are compliant to
              donor requirements. There are Internal Control systems that
              maintain the integrity of the system and safeguard the resources
              of the organization.
            </p>
            <p  className="tw-mb-3">
              Providence Whole Care International has an independent accounting
              capacity that reports to the funds committee all expenditures per
              month. There is an internal auditor and consultant accountant who
              are qualified for the work.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}
