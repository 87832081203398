import React from 'react'
import ourprograms from "assets/images/backgrounds/about-us.jpg";
import OurProgramsCards from './ourprogramscards';
import agrotechnology from "assets/images/siteimages/agrotechnology.jpg";
import economicempowerment from "assets/images/siteimages/economicempowerment.jpg";
import socialprotectionprogram from "assets/images/siteimages/socialprotectionprogram.jpg";
import climatechange from "assets/images/siteimages/climatechange.jpg";
import healthprograms from "assets/images/siteimages/bannerimage2.jpg";
import lobbyingandadvocacy from "assets/images/siteimages/lobbyingandadvocacy.jpg";
import psychosocialsupport from "assets/images/siteimages/psychosocialsupport.jpg";
import researchandtraining from "assets/images/siteimages/researchandtraining.jpg";



export default function Programmes() {
  const programs = [
    {
      image: climatechange,
      title: "Environment Programs",
      subtitle: "Organization Activity",
      description:
        "In response to the pressing challenges posed by climate change, our organization actively engages with grassroots communities to implement innovative strategies that both mitigate harm and leverage opportunities for sustainable development.",
      additionalInfo:
        "In response to the pressing challenges posed by climate change, our organization actively engages with grassroots communities to implement innovative strategies that both mitigate harm and leverage opportunities for sustainable development. Our trans-disciplinary approach emphasizes collaboration and inclusivity, ensuring that community members play a vital role in shaping solutions. By integrating knowledge from diverse fields, we aim to foster resilience and adaptability, transforming both the communities we serve and our research practices. Join us in our commitment to creating a sustainable future for all.",
      pageUrl: "#",
    },
    {
      image: researchandtraining,
      title: "Research and Training",
      subtitle: "Organization Activity",
      description:
        "At Providence Whole Care International, we are dedicated to empowering individuals through tailored programs that provide essential survival skills, including life skills, entrepreneurship, financial literacy, and employability training. Our commitment to research and development ensures that our training is not only relevant but also impactful.",
      additionalInfo:
        "At Providence Whole Care International, we are dedicated to empowering individuals through tailored programs that provide essential survival skills, including life skills, entrepreneurship, financial literacy, and employability training. Our commitment to research and development ensures that our training is not only relevant but also impactful. We collaborate with esteemed partner institutions such as KEMU, USIU, NETFUND, and KRUSCOF to conduct vital research across various fields. Our research initiatives encompass critical areas such as renewable and non-renewable energy, food security, and waste management, along with nutritional surveys and sustainable livelihoods. We also focus on health-related issues including HIV/AIDS, reproductive and mental health, while promoting effective management practices in aquaculture, horticulture, and natural resource conservation. Through these efforts, we aim to equip communities with the knowledge and skills necessary to navigate emerging challenges and foster sustainable development.Join us as we work towards creating a resilient society through research-driven empowerment.",
      pageUrl: "#",
    },
    {
      image: lobbyingandadvocacy,
      title: "Lobbying and Advocacy",
      subtitle: "Organization Activity",
      description:
        "We recognize the critical role of public participation and awareness in addressing environmental and humanitarian challenges. Our lobbying and advocacy initiatives are designed to identify and assess the concerns of communities, ensuring that their voices are heard in decision-making processes.",
      additionalInfo:
        "At Providence Whole Care International, we recognize the critical role of public participation and awareness in addressing environmental and humanitarian challenges. Our lobbying and advocacy initiatives are designed to identify and assess the concerns of communities, ensuring that their voices are heard in decision-making processes. We actively advocate for legislation and policies that address pressing public challenges, organizing workshops and strategic interventions aimed at developing effective programs. Our efforts extend beyond environmental issues, encompassing humanitarian support systems to create comprehensive solutions for the communities we serve. Join us in our mission to empower individuals and influence positive change through advocacy and collaboration.",
      pageUrl: "#",
    },
    {
      image: agrotechnology,
      title: "Innovative Agro-Energy Technological Initiatives",
      subtitle: "Organization Activity",
      description:
        "In collaboration with Kenya Methodist University (KEMU) and KRUSCOF Engineering, is committed to tackling the challenges faced by urban and rural communities through innovative agro-energy solutions.",
      additionalInfo:
        "Providence Whole Care International, in collaboration with Kenya Methodist University (KEMU) and KRUSCOF Engineering, is committed to tackling the challenges faced by urban and rural communities through innovative agro-energy solutions. Our partnership focuses on critical areas such as food security, renewable energy, and climate change mitigation and adaptation. We address key issues related to natural resource management, environmental conservation, and community development, employing a holistic approach that emphasizes the commercialization of appropriate technologies. These initiatives are designed to empower small and medium-sized farmers, ultimately contributing to urban-rural poverty reduction. By fostering innovative solutions that enhance productivity and sustainability, we aim to create a brighter future for communities while promoting environmental stewardship.",
      pageUrl: "#",
    },
    {
      image: healthprograms,
      title: "Health Programs",
      subtitle: "Organization Activity",
      description:
        "At Providence Whole Care International, our Health Programs are designed to improve community well-being through a variety of targeted initiatives. We focus on sensitization and advocacy on critical health issues such as HIV and AIDS, malaria, and other emerging health concerns.",
      additionalInfo:
        "At Providence Whole Care International, our Health Programs are designed to improve community well-being through a variety of targeted initiatives. We focus on sensitization and advocacy on critical health issues such as HIV and AIDS, malaria, and other emerging health concerns. Our outreach includes community forums like chiefs’ meetings, workplaces, educational institutions, women's groups, and religious centers. Our comprehensive approach includes professional counseling and training services, HIV testing and counseling, and the publication of informative materials to raise awareness. We provide home-based care, support for orphans and vulnerable youth, and prevention strategies for those living with HIV. In addition to community-based activities, our clinical services offer preventive and curative treatments, family planning assistance, minor surgical procedures, and maternity services. We also conduct medical camps to provide essential healthcare in underserved informal settlements, ensuring that everyone has access to the medical care they need. Through our awareness campaigns and community mobilization efforts, we aim to foster healthier communities, promote preventative health measures, and empower individuals to take charge of their health.",
      pageUrl: "#",
    },

    {
      image: socialprotectionprogram,
      title: "Social Protection Programs",
      subtitle: "Organization Activity",
      description:
        "Our Social Protection Programs at Providence Whole Care International are dedicated to supporting vulnerable populations, including Orphans and Vulnerable Children (OVCs) and People Living with HIV/AIDS (PLWHAs). We provide comprehensive psycho-social programs designed to enhance the mental and emotional well-being of individuals and families facing challenges.",
      additionalInfo: "Our Social Protection Programs at Providence Whole Care International are dedicated to supporting vulnerable populations, including Orphans and Vulnerable Children (OVCs) and People Living with HIV/AIDS (PLWHAs). We provide comprehensive psycho-social programs designed to enhance the mental and emotional well-being of individuals and families facing challenges. Key initiatives include sponsorships that cover basic needs for OVCs and psycho-education training that is accessible both in and out of school, empowering youth with essential life skills and knowledge. Additionally, we tackle the pressing issue of Gender-Based Violence (GBV) through a multifaceted approach that includes psychological support, safe shelter for survivors, and referrals to necessary services. Our programs also offer post-exposure prophylaxis and treatment for opportunistic infections, ensuring that individuals affected by GBV receive the care and support they need. Through these initiatives, we strive to create a safer, more supportive environment for all members of our communities, promoting healing, empowerment, and resilience.",
      pageUrl: "#",
    },
    {
      image: economicempowerment,
      title: "Economic Empowerment",
      subtitle: "Organization Activity",
      description:
        "Our Economic Empowerment programs are designed to uplift grassroots communities through a variety of innovative initiatives. We focus on fostering self-sufficiency and enhancing livelihoods by providing practical income generation activities such as bee keeping, handcrafting, beadwork, and poultry keeping.",
      additionalInfo: "At Providence Whole Care International, our Economic Empowerment programs are designed to uplift grassroots communities through a variety of innovative initiatives. We focus on fostering self-sufficiency and enhancing livelihoods by providing practical income generation activities such as bee keeping, handcrafting, beadwork, and poultry keeping. These programs not only create sustainable income sources but also encourage creativity and entrepreneurship. We prioritize education and skill development through our training programs, which encompass entrepreneurship, financial literacy, and employability skills. These workshops equip individuals with the necessary tools to navigate the job market and manage their finances effectively. In addition to training, we facilitate community support systems like merry-go-round groups and revolving funds, which promote savings and collective financial management among participants. Moreover, we welcome volunteers to join us in our mission, fostering collaboration and support for our ongoing projects. Together, we strive to build resilient communities capable of achieving economic independence and sustainable growth.",
      pageUrl: "#",
    },
    // Add more programs as needed
  ];

  return (
    <div>
      <div className="tw-relative tw-h-[50vh]">
        <img
          src={ourprograms}
          alt="Background"
          className="tw-object-cover tw-w-full tw-h-full tw-opacity-70"
        />
        <div className="tw-absolute tw-inset-0 tw-bg-black tw-opacity-50" />{" "}
        {/* Overlay */}
        <div className="tw-absolute tw-inset-0 tw-flex md:tw-mx-32 tw-left-10 tw-items-center">
          <div className="md:tw-w-2/3">
            <h5 className="tw-text-white tw-text-3xl md:tw-text-6xl tw-font-bold">
              Our Programs
            </h5>
          </div>
        </div>
      </div>
      <section className="tw-mb-10 tw-p-10 md:tw-mx-auto tw-max-w-screen-xl tw-relative">
        <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-4 md:tw-gap-10">
          {programs.map((program, index) => (
            <OurProgramsCards key={index} program={program} />
          ))}
        </div>
      </section>
    </div>
  );
}
