import React from "react";
import AchievementsHeroImage from "assets/images/backgrounds/achievements.jpg";
import { MdOutlineSupportAgent } from "react-icons/md";
import { RiStarSmileLine } from "react-icons/ri";
import { GiBrain } from "react-icons/gi";
import CountersCard from "./counters";
import { Card, Row, Col } from "react-bootstrap";
import AchievementsDisplay from "./AchievementsDisplay";
import { GiAfrica } from "react-icons/gi";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function Achievements() {
  const { ref: imageRef, inView: imageInView } = useInView({
    triggerOnce: true, // Only trigger the animation once
    threshold: 0.3, // Trigger when 30% of the image is in view
  });

  const { ref: textRef, inView: textInView } = useInView({
    triggerOnce: true, // Only trigger the animation once
    threshold: 0.3, // Trigger when 30% of the text section is in view
  });
  const AchievementsCards = () => {
    return (
      <Row>
        <Col md={6} lg={3}>
          <CountersCard
            title="Government & Strategic Support"
            counterTitle="Projects Supported"
            counterValue="10+"
            description="Supported in writing government sector plans, business plans, and policies. Conducted research projects like the Nairobi River Drainage System."
          />
        </Col>
        <Col md={6} lg={3}>
          <CountersCard
            title="Counseling & Education Programs"
            counterTitle="People Reached"
            counterValue="12000+"
            description="Provision of individual and group counseling and training. Monthly counselor supervision for VCT counselors. Intensive grassroots sensitization and mobilization on social issues."
          />
        </Col>
        <Col md={6} lg={3}>
          <CountersCard
            title="Community Health & Clinical Services"
            counterTitle="Clinical Services Provided"
            counterValue="8+"
            description="Integrated clinical services for treatment, diagnostics, family planning, and maternity. Demonstration and distribution of condoms and IEC materials."
          />
        </Col>
        <Col md={6} lg={3}>
          <CountersCard
            title="Advocacy & Community Outreach"
            counterTitle="Initiatives & Campaigns"
            counterValue="15+"
            description="Established post-test clubs, peer educator programs, and prevention support clubs. Conducted Behavior Change Communication campaigns targeting youth."
          />
        </Col>
      </Row>
    );
  };
  return (
    <div>
      <div className="tw-relative tw-h-[50vh]">
        <img
          src={AchievementsHeroImage}
          alt="Background"
          className="tw-object-cover tw-w-full tw-h-full tw-opacity-70"
        />
        <div className="tw-absolute tw-inset-0 tw-bg-black tw-opacity-80" />{" "}
        {/* Overlay */}
        <div className="tw-absolute tw-inset-0 tw-flex md:tw-mx-32 tw-left-10 tw-items-center">
          <div className="md:tw-w-2/3">
            <h5 className="tw-text-white tw-text-3xl md:tw-text-6xl tw-font-bold">
              Achievements
            </h5>
          </div>
        </div>
      </div>
      <div className="tw-text-center tw-flex tw-flex-col tw-w-4/6 tw-m-auto tw-mt-10">
        <motion.div
          initial={{ opacity: 0, y: -50 }} // Starts above and invisible
          animate={{ opacity: 1, y: 0 }} // Moves into position when in view
          transition={{ duration: 1 }} // Duration of the animation
        >
          <h4 className="tw-text-4xl">
            Celebrating a Decade of Impact: Our Key Achievements
          </h4>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 50, x: 0 }} // Starts from below and with slight horizontal offset
          animate={{ opacity: 1, y: 0, x: 0 }} // Moves into position when in view
          transition={{ duration: 1, delay: 0.3 }} // Slight delay to make the second one appear after
        >
          <h5 className="tw-text-xl tw-mt-10">
            Over the past ten years, we've made significant strides in driving
            change. Explore our achievements across strategic planning,
            community outreach, counseling, and health services, each
            represented in a focused category with impactful metrics and
            highlights.
          </h5>
        </motion.div>
      </div>
      <div className="md:tw-w-10/12 tw-m-auto md:tw-mt-10 tw-mt-5">
        <AchievementsCards />
      </div>
      <div className="tw-flex tw-flex-col md:tw-flex-row tw-mt-16 tw-mb-10 tw-px-5 md:tw-px-24">
        {/* First Div (Image) */}
        <motion.div
          ref={imageRef} // Attach the intersection observer to this div
          initial={{ x: -100, opacity: 0 }} // Starts from the left with opacity 0
          animate={{ x: imageInView ? 0 : -100, opacity: imageInView ? 1 : 0 }} // Only animate when in view
          transition={{ duration: 1 }} // Duration of the animation
          className="tw-h-3/5 tw-flex-1 tw-overflow-hidden tw-mx-auto"
        >
          <img
            src={AchievementsHeroImage}
            alt=""
            className="tw-rounded-tr-[200px] tw-object-cover tw-h-[20] tw-w-full"
          />
        </motion.div>

        {/* Second Div (Text) */}
        <motion.div
          ref={textRef} // Attach the intersection observer to this div
          initial={{ x: 100, opacity: 0 }} // Starts from the right with opacity 0
          animate={{ x: textInView ? 0 : 100, opacity: textInView ? 1 : 0 }} // Only animate when in view
          transition={{ duration: 1 }} // Duration of the animation
          className="tw-flex-1 md:tw-pl-10 tw-flex tw-items-center"
        >
          <div className="tw-flex tw-flex-col">
            <div>
              <p className="tw-text-[2rem]">
                Winner of UFADD for Africa Digital Development Program
              </p>
            </div>

            <div className="tw-flex tw-flex-col md:tw-grid md:tw-grid-cols-6 md:tw-gap-2 tw-mt-3 md:tw-mt-6">
              <div className="tw-col-span-1">
                <GiAfrica size="100px" />
              </div>
              <div className="tw-ml-4 tw-col-span-5">
                <p className="tw-text-[1.5rem] tw-font-bold">
                  Initiatives & Impact
                </p>
                <p className="tw-leading-8 tw-mb-4 tw-italic">
                  UFADD Program to transform Africa digitally.
                </p>
                <p className="tw-leading-8">
                  The UFADD program is revolutionizing Africa's digital
                  landscape, with a focus on achieving Sustainable Development
                  Goals (SDGs). Our initiatives aim to equip SMEs across the
                  continent with the tools to expand their reach globally. By
                  fostering digital transformation, we help businesses scale up,
                  connect with international markets, and contribute to Africa’s
                  growth and development.
                </p>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
      <AchievementsDisplay />
    </div>
  );
}
