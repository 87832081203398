import React from "react";
import { Card } from "react-bootstrap";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import "bootstrap/dist/css/bootstrap.min.css";

export default function CountersCard({ title, counterTitle, counterValue, description }) {
  // Use intersection observer hook to detect when the card is in view
  const { ref, inView } = useInView({
    triggerOnce: true, // Only animate once
    threshold: 0.3,    // Trigger when 30% of the card is in view
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}  // Start off-screen and invisible
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }} // Animate to full opacity and original position when in view
      transition={{ duration: 0.8 }} // Smooth transition
      className="tw-shadow-md tw-rounded-lg tw-p-5 tw-m-4 tw-text-center"
    >
      <Card.Body>
        <h5 className="tw-font-semibold tw-text-gray-600">{counterTitle}</h5>
        <h3 className="tw-text-4xl tw-font-bold tw-text-prim-orange tw-my-2">
          {inView ? ( // Only animate the counter when in view
            <CountUp
              end={parseInt(counterValue.replace(/\D/g, ""))} // Extract numerical value
              duration={2.5}
              separator=","
            />
          ) : (
            "0" // Show 0 before the counter starts
          )}
          +
        </h3>
        <Card.Title className="tw-text-2xl tw-font-bold">{title}</Card.Title>
        <Card.Text className="tw-text-gray-700 tw-mt-4">{description}</Card.Text>
      </Card.Body>
    </motion.div>
  );
}
