import React from "react";
import AboutUsImage from "assets/images/backgrounds/about-us.jpg";
import Image1 from "assets/images/siteimages/Community.jpg";
import GoalsImage from "assets/images/siteimages/image1.jpg";

export default function About() {
  return (
    <div className=" tw-bg-gray-100">
      <div className="tw-relative tw-h-[50vh]">
        <img
          src={AboutUsImage}
          alt="Background"
          className="tw-object-cover tw-w-full tw-h-full tw-opacity-70"
        />
        <div className="tw-absolute tw-inset-0 tw-bg-black tw-opacity-50" />{" "}
        {/* Overlay */}
        <div className="tw-absolute tw-inset-0 tw-flex md:tw-mx-32 tw-left-10 tw-items-center">
          <div className="md:tw-w-2/3">
            <h5 className="tw-text-white tw-text-3xl md:tw-text-6xl tw-font-bold">
              Our Mission
            </h5>
            <p className="tw-mt-4 tw-text-white">
              {" "}
              To provide comprehensive professional services that promote a
              clean and healthy environment, enhancing socio-economic
              development.
            </p>
          </div>
        </div>
      </div>

      <section className="tw-mb-10 tw-p-10 md:tw-mx-32">
        <div className="tw-relative tw-inline-block tw-mb-10 tw-text-prim-purple">
          <h2 className="tw-text-3xl md:tw-text-4xl tw-font-bold tw-mb-4">
            About Us
          </h2>
          <div className="tw-absolute tw-left-0 tw-bottom-0 tw-w-1/3 tw-h-1 tw-bg-prim-purple tw-mt-2" />
        </div>

        <p className="tw-text-gray-700 tw-mb-10 tw-text-lg">
          Providence Whole Care International (PWCI) was established in 2002 as
          a Professional Counseling Unit, offering individual and group
          therapies. In 2003, the Providence Voluntary Counseling and Testing
          (VCT) Center was integrated, followed by the initiation of the
          Wholistic Care Faith-Based organization in 2004. PWCI transformed into
          a Non-Governmental Organization (NGO) in 2007. Our main activities
          include mental health counseling, HIV/AIDS services, early childhood
          development (ECD) programs, advocacy, and youth empowerment. We
          collaborate with partners like ACT! to advance environmental
          legislation and engage in ongoing mental health research with
          institutions such as Kenya Methodist University.
        </p>

        <h3 className="tw-text-xl tw-font-bold ">Mission Statement</h3>
        <p className="tw-text-gray-700 tw-mb-10 tw-text-lg">
          To provide comprehensive professional services that promote a clean
          and healthy environment, enhancing socio-economic development.
        </p>

        <h3 className="tw-text-xl tw-font-bold">Vision Statement</h3>
        <p className="tw-text-gray-700 tw-text-lg">
          People living dignified lives in a clean, healthy, and sustainably
          managed environment.
        </p>
      </section>

      <section>
        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2">
          <div className="tw-bg-gray-200 tw-flex tw-justify-center">
            <div className="tw-p-4 md:tw-p-10 md:tw-m-auto md:tw-w-9/12 tw-flex tw-flex-col ">
              {" "}
              <div className="tw-relative tw-inline-block tw-mb-10 tw-text-prim-dark tw-w-max">
                <h4 className="tw-text-xl md:tw-text-2xl tw-font-bold tw-mb-4">
                  General Goals
                </h4>
                <div className="tw-absolute tw-left-0 tw-bottom-0 tw-w-1/3 tw-h-1 tw-bg-prim-orange tw-mt-2" />
              </div>
              <ul className="tw-list-disc tw-list-inside tw-text-gray-700">
                <li>
                  Scale up program activities in environment, HIV/AIDS, gender
                  equality, and emerging issues.
                </li>
                <li>
                  Sensitize individuals and communities on acquiring quality
                  survival skills.
                </li>
                <li>
                  Foster systems that increase bio-psycho-socio-economic and
                  environmental support.
                </li>
                <li>
                  Educate communities to reduce stigma against orphans,
                  vulnerable children, and those affected by HIV/AIDS.
                </li>
                <li>
                  Collaborate with local, national, and international
                  organizations to share resources and information.
                </li>
              </ul>
            </div>
          </div>
          <div className="tw-relative tw-h-full tw-w-full">
            {" "}
            {/* Right column for image */}
            <img
              src={GoalsImage} // Update with your image path
              alt="Goals Illustration"
              className="tw-object-cover tw-h-full tw-w-full "
            />
          </div>
        </div>

        <div className="tw-mb-10 tw-grid tw-grid-cols-1 md:tw-grid-cols-2">
          <div className="tw-relative tw-h-full tw-w-full">
            {" "}
            {/* Left column for image */}
            <img
              src={Image1} // Update with your image path
              alt="Objectives Illustration"
              className="tw-object-cover tw-h-full tw-w-full"
            />
          </div>
          <div className="tw-bg-gray-200 tw-flex tw-justify-center">
            <div className="tw-p-4 md:tw-p-10 md:tw-m-auto md:tw-w-9/12 tw-flex tw-flex-col">
              {" "}
              {/* Right column for objectives */}
              <div className="tw-relative tw-inline-block tw-mb-4 tw-text-prim-dark tw-w-max">
                <h4 className="tw-text-xl md:tw-text-2xl tw-font-bold tw-mb-4">
                  Objectives
                </h4>
                <div className="tw-absolute tw-left-0 tw-bottom-0 tw-w-1/3 tw-h-1 tw-bg-prim-orange tw-mt-2" />
              </div>
              <p className="tw-text-gray-700">
                PWCI's main objective is to identify community needs and engage
                with relevant government bodies to address issues in the areas
                of environment, health, food security, education, and economic
                empowerment.
              </p>
              <h5 className="tw-text-xl tw-font-semibold tw-mb-4 tw-mt-2">
                Specific Objectives
              </h5>
              <ul className="tw-list-disc tw-list-inside tw-text-gray-700">
                <li>
                  Provide farming and storage technology to vulnerable
                  populations to alleviate hunger and malnutrition.
                </li>
                <li>
                  Offer curative and preventive medicines to improve public
                  health.
                </li>
                <li>Deliver education and skills training to youth.</li>
                <li>
                  Extend psychological support to individuals with mental
                  disorders.
                </li>
                <li>
                  Ensure shelter and protection for those affected by
                  gender-related issues.
                </li>
                <li>
                  Build the capacity of orphans and vulnerable children (OVCs)
                  and the general population to enhance income.
                </li>
                <li>
                  Provide psychosocial support to those displaced by violence.
                </li>
                <li>
                  Raise environmental awareness for sustainable practices.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="tw-p-10 md:tw-mx-32">
        <div className="tw-flex-1 tw-flex tw-flex-col tw-p-4">
          <div className="tw-relative tw-inline-block tw-mb-5 tw-text-prim-dark tw-w-max">
            <h4 className="tw-text-xl md:tw-text-2xl tw-font-bold tw-mb-4">
              Target Areas
            </h4>
            <div className="tw-absolute tw-left-0 tw-bottom-0 tw-w-1/3 tw-h-1 tw-bg-prim-orange tw-mt-2" />
          </div>
          <p className="tw-text-gray-700">
            Our services are designed for all individuals, regardless of race,
            nationality, social class, or political inclination. We primarily
            operate in Nairobi, Kiambu, Meru, Isiolo, Machakos, and Kajiado
            Counties.
          </p>
        </div>

        <div className="tw-flex-1 tw-flex tw-flex-col  tw-p-4 tw-mb-10">
          <div className="tw-relative tw-inline-block tw-mb-5 tw-text-prim-dark tw-w-max">
            <h4 className="tw-text-xl md:tw-text-2xl tw-font-bold tw-mb-4">
              Role of Beneficiaries
            </h4>
            <div className="tw-absolute tw-left-0 tw-bottom-0 tw-w-1/3 tw-h-1 tw-bg-prim-orange tw-mt-2" />
          </div>
          <p className="tw-text-gray-700">
            Beneficiaries are seen as integral stakeholders, serving as project
            activity drivers, peer counselors, community coordinators, health
            workers, evaluators, and leaders. Our staff and volunteers lead
            program activities, supported by management supervision and capacity
            development.
          </p>
        </div>
      </section>
    </div>
  );
}
