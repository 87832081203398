import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const achievements = [
  "Supported in writing of government sector strategic plans, business plans and policies.",
  "Conducted Research projects especially Nairobi River Drainage System.",
  "Provision of both Individuals and group’s professional counseling and training services.",
  "Intensive sensitization and mobilization at grassroots on issues affecting the society such as HIV/AIDS, drug abuse, and gender violence.",
  "Voluntary Counseling and testing at the site, Door to Door and community mobile outreaches where over 12000 people have benefited.",
  "Establishment of Post-Test clubs, peer educator’s programs, Support programs for PLWHA and prevention clubs.",
  "Developed training programs for Home and Community caregivers who operate with grass root populations.",
  "Provide tailor made seminars and workshops and organized community groups and institutions.",
  "Provided monthly counselor supervision for VCT counselor’s locality.",
  "Help in formation of a working team with the Karen Pastor and leadership fellowship and the civil society within our locality.",
  "Provided active systems of demonstration, distribution of condoms and IEC materials.",
  "Integrated clinical services especially treatment and care; laboratory diagnostics of various illnesses; family planning and maternity.",
  "Establishment of referral systems and Networks.",
  "Conducted Behavior Change Communication campaigns for the youth.",
  "Planned and executed two grand walks covering 250 km and 600km respectively."
];

const AchievementsDisplay = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3
  });

  return (
    <div ref={ref} className="achievements-container tw-px-10 tw-py-20 md:tw-w-9/12 tw-m-auto">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: inView ? 1 : 0 }}
        transition={{ duration: 1 }}
      >
        <h2 className="tw-text-3xl tw-font-bold tw-text-center tw-mb-6">
          Achievements in the Last Ten Years
        </h2>
        <motion.ul
          initial={{ opacity: 0 }}
          animate={{ opacity: inView ? 1 : 0 }}
          transition={{ duration: 1 }}
          className="tw-list-none tw-px-4"
        >
          {achievements.map((achievement, index) => (
            <motion.li
              key={index}
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: inView ? 1 : 0 }}
              transition={{ duration: 0.8, delay: index * 0.2 }}
              className="tw-text-lg tw-text-gray-700 tw-mb-4 tw-pl-2 tw-border-l-4 tw-border-prim-orange"
            >
              <div className='tw-p-4 tw-shadow-md hover:tw-bg-prim-purple hover:tw-text-white tw-transition tw-ease-in-out tw-duration-300'>♦ {achievement}</div>
            </motion.li>
          ))}
        </motion.ul>
      </motion.div>
    </div>
  );
};

export default AchievementsDisplay;
