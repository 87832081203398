import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel";
import { motion } from "framer-motion";
import { AboutUsPageLink } from "lib/constants";

export default function HomeCarousel({ items }) {
  const carouselItemStyle = {
    height: "inherit", // Ensure full viewport height
    position: "relative",  // To position the overlay correctly
  };

  const imageStyle = {
    objectFit: "cover",  // Ensure image covers the container
    height: "100%", // Ensure the image takes full height of the container
    transition: "opacity 1s ease-in-out", // Smooth transition
  };

  return (
    <div className="tw-h-[85vh] ">
      <Carousel fade interval={3500} style={carouselItemStyle}>
        {items.map((item, index) => (
          <Carousel.Item key={index} interval={3500} style={carouselItemStyle}>
            {/* Image with fixed background effect */}
            <motion.div
              style={{
                backgroundImage: `url(${item.image})`,
                backgroundAttachment: "fixed", // Fix the background image
                backgroundSize: "cover", // Ensure the image covers the area
                backgroundPosition: "center", // Center the image
                height: "85vh", // Ensure the image takes full height of the container
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }} // Smooth fade-in for images
              transition={{ duration: 1, ease: "easeInOut" }} // Smooth transition
            >
              
            </motion.div>

            {/* Overlay with motion text */}
            <div className="tw-absolute tw-inset-0 tw-bg-black tw-opacity-60 tw-flex tw-flex-col tw-justify-center tw-items-center">
              <motion.div
                initial={{ opacity: 0, y: 20 }} // Start faded out and slightly below
                animate={{ opacity: 1, y: 0 }} // Fade in and move to original position
                transition={{ duration: 0.8, ease: "easeOut" }} // Smooth transition
                className="tw-text-center tw-flex tw-flex-col tw-justify-center tw-items-center"
              >
                <div className="tw-px-4  md:tw-w-2/3 md:tw-px-4 tw-text-center tw-flex tw-flex-col tw-justify-center tw-items-center">
                  <motion.span
                    initial={{ opacity: 0, y: -50 }} // Animate from top (above)
                    animate={{ opacity: 1, y: 0 }} // Fade in and slide to the original position
                    transition={{ duration: 0.8, ease: "easeOut" }}
                    className="tw-text-white tw-text-4xl md:tw-text-6xl tw-font-bold tw-opacity-100"
                  >
                    {item.overlayText}
                  </motion.span>
                  <motion.span
                    initial={{ opacity: 0, y: 50 }} // Animate from bottom (below)
                    animate={{ opacity: 1, y: 0 }} // Fade in and slide to the original position
                    transition={{ duration: 0.8, ease: "easeOut" }}
                    className="tw-text-white tw-mt-4 tw-text-lg tw-font-semibold"
                  >
                    <div>
                      <p> {item.overlayTextDescription}</p>

                      <a
                        href={AboutUsPageLink}
                        className="tw-btn-primary tw-mt-8 tw-font-semibold"
                      >
                        Learn More
                      </a>
                    </div>
                  </motion.span>
                </div>
              </motion.div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}
