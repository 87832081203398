import React from "react";

export default function CardContent({ card }) {
  return (
    <div className="image-card-icon trans">
      <a href={card.linkUrl}>
        <div
          className={`image-card-icon-hover tw-w-full tw-h-full trans  ${card.bgcolor}`}
        >
          <div>
            <p className="h5-title">{card.title}</p>
            <p>{card.description}</p>
          </div>
        </div>
        <div
          className="image-card-icon-img trans"
          style={{
            background: `url(${card.imageUrl}) center center no-repeat`,
          }}
        />
        <div className={`image-card-icon-holder trans ${card.bgcolorlight}`}>
          <div className="image-card-icon-icon">
            <img src={card.iconUrl} alt={card.title} />
          </div>
        </div>
        <div className="image-card-icon-title">
          <p style={{ fontSize: "20px", lineHeight: 1.2 }}>{card.title}</p>
        </div>
      </a>
    </div>
  );
}
