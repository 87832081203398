import React from "react";
import facebookIcon from "assets/images/icons/facebook.png";
import twitterIcon from "assets/images/icons/twitter.png";
import linkedinIcon from "assets/images/icons/linkedin.png";
import { AboutUsPageLink, AchievementsPageLink, HomePageLink, PartnersPageLink, ProgrammesPageLink } from "lib/constants";

export default function Footer() {
  return (
    <>
      <footer className="tw-bg-prim-purple tw-text-white">
        <div className="tw-container tw-mx-auto tw-p-0 md:tw-p-8 xl:tw-px-0">
          <div className="tw-mx-auto tw-max-w-7xl tw-px-6 tw-pb-10 tw-pt-16">
            <div className="xl:tw-grid xl:tw-grid-cols-3 xl:tw-gap-8">
              <div className="tw-space-y-4">
                <div>
                  <a href={HomePageLink}>
                    <div className="tw-flex tw-items-center tw-space-x-2 tw-text-2xl tw-font-medium">
                      <span>
                        <img
                          src="https://www.svgrepo.com/show/452102/slack.svg"
                          alt="PWCI Logo"
                          width="64"
                          height="64"
                          className="tw-w-16"
                        />
                      </span>
                      <span className="tw-text-white">PWCI</span>
                    </div>
                  </a>
                </div>
                <div className="tw-max-w-md tw-pr-16 tw-text-md tw-text-white">
                  Empowering communities through sustainable solutions in
                  health, environment, and economic empowerment.
                </div>
                <div className="tw-flex tw-space-x-2">
                  <a
                    href="#"
                    target="_blank"
                    className="tw-text-white hover:tw-text-white"
                  >
                    <img src={facebookIcon} className="tw-h-10 tw-w-auto" />
                  </a>
                  <a
                    href="#"
                    target="_blank"
                    className="tw-text-white hover:tw-text-white"
                  >
                    <img src={twitterIcon} className="tw-h-10 tw-w-auto" />
                  </a>
                  <a
                    href="#"
                    target="_blank"
                    className="tw-text-white hover:tw-text-white"
                  >
                    <img src={linkedinIcon} className="tw-h-10 tw-w-auto" />
                  </a>
                </div>
                <div>
                  <p> info@pwci.or.ke <br/> pwcion@yahoo.com</p>
                </div>
              </div>
              <div className="tw-mt-16 tw-grid tw-grid-cols-2 tw-gap-8 xl:tw-col-span-2 xl:tw-mt-0">
                <div className="md:tw-grid md:tw-grid-cols-2 md:tw-gap-8">
                  <div>
                    <h3 className="tw-text-md tw-font-semibold tw-leading-6 tw-text-white">
                      Quick Links
                    </h3>
                    <ul role="list" className="tw-mt-6 tw-space-y-4">
                      <li>
                        <a
                          href={HomePageLink}
                          className="tw-text-md tw-leading-6 tw-text-white hover:tw-text-gray-500"
                        >
                          Home
                        </a>
                      </li>
                      <li>
                        <a
                          href={AboutUsPageLink}
                          className="tw-text-md tw-leading-6 tw-text-white hover:tw-text-gray-500"
                        >
                          About Us
                        </a>
                      </li>
                      <li>
                        <a
                          href={ProgrammesPageLink}
                          className="tw-text-md tw-leading-6 tw-text-white hover:tw-text-gray-500"
                        >
                          Programs
                        </a>
                      </li>
                      <li>
                        <a
                          href={HomePageLink}
                          className="tw-text-md tw-leading-6 tw-text-white hover:tw-text-gray-500"
                        >
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="tw-mt-10 md:tw-mt-0">
                    <h3 className="tw-text-md tw-font-semibold tw-leading-6 tw-text-white">
                      Get Involved
                    </h3>
                    <ul role="list" className="tw-mt-6 tw-space-y-4">
                      <li>
                        <a
                          href={HomePageLink}
                          className="tw-text-md tw-leading-6 tw-text-white hover:tw-text-gray-500"
                        >
                          Donate
                        </a>
                      </li>
                      <li>
                        <a
                          href={HomePageLink}
                          className="tw-text-md tw-leading-6 tw-text-white hover:tw-text-gray-500"
                        >
                          Volunteer
                        </a>
                      </li>
                      <li>
                        <a
                          href={PartnersPageLink}
                          className="tw-text-md tw-leading-6 tw-text-white hover:tw-text-gray-500"
                        >
                          Partners
                        </a>
                      </li>
                      <li>
                        <a
                          href={AchievementsPageLink}
                          className="tw-text-md tw-leading-6 tw-text-white hover:tw-text-gray-500"
                        >
                          Achievements
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="md:tw-grid tw-w-2/3">
                  <div>
                    <h3 className="tw-text-md tw-font-semibold tw-leading-6 tw-text-white">
                      Stay In Touch
                    </h3>
                    <div className="tw-mt-6">
                      <form className="tw-space-y-4 tw-flex tw-flex-col tw-items-left">
                        <div className="tw-w-full tw-border-b tw-border-white focus-within:tw-border-primary">
                          <input
                            type="text"
                            name="name"
                            placeholder="Name"
                            className="tw-w-full tw-bg-transparent tw-outline-none tw-text-white tw-placeholder-white tw-px-2 tw-py-2"
                          />
                        </div>
                        <div className="tw-w-full tw-border-b tw-border-white focus-within:tw-border-primary">
                          <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            className="tw-w-full tw-bg-transparent tw-outline-none tw-text-white tw-placeholder-white tw-px-2 tw-py-2"
                          />
                        </div>
                        <button
                          type="submit"
                          className="tw-w-max tw-py-2 tw-px-2 tw-bg-white tw-text-prim-purple tw-font-semibold tw-rounded-md tw-transition-colors hover:tw-bg-gray-200"
                        >
                          Subscribe
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tw-mt-16 tw-border-t tw-border-gray-400/30 tw-pt-8 sm:tw-mt-20 lg:tw-mt-24">
              <div className="tw-text-md tw-text-center tw-text-white">
                Copyright © 2024 . Providence Whole Care International. Crafted
                with
                <span className="tw-text-gray-50">♥</span> for lasting impact.
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
