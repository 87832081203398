import React from 'react'
import Navbar from 'components/navigation/navbar';
import Footer from 'components/navigation/footer';
import { AppStateContext } from 'context/appcontext';

export default function SiteLayout({children}) {
    const { notification } = AppStateContext();
  return (
    <div className="tw-relative">
      <Navbar />
      <div className="tw-pt-0 tw-relative  tw-min-h-[70vh] tw-z-auto">
        {notification && (
          <div className="notification-toast tw-px-7 tw-py-3 tw-rounded-full">
            {notification}
          </div>
        )}
        <div>{children}</div>
      </div>
      <Footer />
    </div>
  );
}
