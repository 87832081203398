import React, { useRef } from "react";
import HomeCarousel from "./carousel";
import ProgrammesCards from "./programmescards";
import image1 from "assets/images/siteimages/image1.jpg";
import image2 from "assets/images/siteimages/image2.jpg";
import image3 from "assets/images/siteimages/image3.jpg";
import BannerImage1 from "assets/images/backgrounds/BannerImage1.jpg"
import BannerImage2 from "assets/images/backgrounds/bannerimage2.jpg"
import BannerImage3 from "assets/images/backgrounds/Bannerimage3.jpg"
import eventsBgImage from "assets/images/backgrounds/event.jpg"; // Adjust the path based on your structure
import UpcomingEvents from "./upcomingevents";
import { FaArrowRightLong } from "react-icons/fa6";
import { AboutUsPageLink, AllEventsPageLink } from "lib/constants";
import { motion, useInView } from "framer-motion";

export default function Home() {

  const ref = useRef(null);
  const isInView = useInView(ref, { triggerOnce: true, threshold: 0.2 });

  const carouselItems = [
    {
      image: BannerImage1,
      label: "Label for third slide",
      description: "Sample Text for Image Three",
      overlayText: "Empowering Lives, Enriching Communities",
      overlayTextDescription:
        "Providence Whole Care International (PWCI) is committed to fostering dignified lives and sustainable environments across Kenya.",
    },
    {
      image: BannerImage2,
      label: "Label for second slide",
      description: "Comprehensive Health Programs for Stronger Communities",
      overlayText: "Health Programs",
      overlayTextDescription:
        "Providence Whole Care International is dedicated to enhancing community health and well-being through essential services and targeted outreach.",
    },
    {
      image: BannerImage3,
      label: "Label for first slide",
      description: "Sample Text for Image One",
      overlayText: "Environment/ Climate change and adaptation",
      overlayTextDescription:
        "Through climate-smart agriculture, Providence Whole Care International partners with communities in arid regions like Samburu County to strengthen resilience against climate change.",
    }
  ];

  const eventsData = [
    {
      title: "The PWCI Honored with Statewide Awards",
      image: image1,
      link: "/event1",
      description: "Event Description",
    },
    {
      title: "Another Upcoming Event Description Here",
      image: image2,
      link: "/event2",
      description: "Event Description",
    },
    {
      title: "Yet Another Event Coming Soon",
      image: image3,
      link: "/event3",
      description: "Event Description",
    },
  ];

  return (
    <div className="tw-relative">
      <div className="tw-w-full tw-h-[75vh] md:tw-h-[85vh] tw-z-10 tw-relative tw-overflow-hidden">
        <HomeCarousel items={carouselItems} />
      </div>
      <div className=" tw-relative tw-mb-10">
        {/* first section*/}
        <div className="tw-my-4 md:tw-w-9/12 tw-px-4 md:tw-px-12 tw-m-auto tw-min-h-[70vh] tw-flex tw-flex-col tw-justify-center tw-items-center tw-align-middle tw-z-20 ">
          <div className="tw-hidden tw-border tw-borde-red-400 tw-mb-10 -tw-mt-28 md:tw-w-full tw-z-30">
            <div className="tw-text-white">
              <p>Item 1</p>
            </div>

            <div className="tw-bg-white md:tw-w-full tw-h-[50vh] tw-mt-4 tw-rounded-xl tw-shadow-lg tw-p-6">
              <div className="tw-flex tw-justify-center tw-items-center tw-h-full tw-overflow-hidden">
                <div
                  className="tw-bg-cover tw-bg-center tw-h-full tw-w-full tw-relative tw-z-10"
                  style={{ backgroundImage: `url(${BannerImage3})` }}
                >
                  {/* Content over the background image */}
                  <div className=" tw-w-full tw-h-full tw-grid tw-grid-cols-6 tw-z-20 ">
                    <div className="tw-col-span-3 ">
                      <p>lorem goes here</p>
                    </div>
                    <div className="tw-col-span-3 tw-bg-prim-ot-purple">
                      wewe unadhani hii ni nini
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* First svg */}
          <div className="img-page-banner-svg-a tw-z-0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="449.704"
              height="449.704"
              viewBox="0 0 449.704 449.704"
            >
              <path
                id="Path_18427"
                data-name="Path 18427"
                d="M209.852,434.7C85.868,434.7-15,333.836-15,209.852S85.868-15,209.852-15V55C124.466,55,55,124.466,55,209.852S124.466,364.7,209.852,364.7,364.7,295.238,364.7,209.852h70C434.7,333.836,333.836,434.7,209.852,434.7Z"
                transform="translate(434.704 15) rotate(90)"
                fill="#24B09B"
              ></path>
            </svg>
          </div>
          {/* Info content */}
          <div className=" tw-z-10">
          <motion.div
            ref={ref} // Attach the ref to track when this section is in view
            initial={{ opacity: 0, y: 20 }}
            animate={isInView ? { opacity: 1, y: 0 } : {}} // Only animate when in view
            transition={{ duration: 0.8, ease: "easeOut" }}
          >
            <div className="tw-text-center tw-w-full tw-p-4 tw-z-10">
              <h2 className="tw-text-4xl tw-text-gray-500">
                "Empowering Communities for a Healthier, Sustainable Future"
              </h2>
            </div>
            <div className="tw-text-center tw-w-full tw-p-4 md:tw-w-10/12 tw-m-auto">
              <p className="tw-text-xl">
                We are dedicated to fostering resilient communities through
                comprehensive counseling, health programs, environmental
                advocacy, and economic empowerment, working hand-in-hand with
                local and global partners to promote dignified lives and
                sustainable progress.
              </p>
            </div>
            <div className="tw-my-4 tw-flex tw-flex-row tw-justify-center tw-align-center tw-w-full tw-mb-10">
              <a href={AboutUsPageLink} className="tw-btn-primary">
                About Us
              </a>
            </div>
          </motion.div>
          </div>
          {/* Second svg */}
          <div className="img-page-banner-svg-b tw-z-0">
            <svg
              version="1.1"
              className="inner_svg"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 338.4 338.4"
              style={{ enableBackground: "new 0 0 338.4 338.4" }}
              xmlSpace="preserve"
            >
              <style type="text/css">{`
            .st0xx { fill: #EF5483; }
          `}</style>
              <g>
                <path
                  id="Path_18404"
                  className="st0xx"
                  d="M169.3,29.3c37.1-0.1,72.7,14.7,99,40.9c54.6,54.6,54.6,143.3,0,197.9L254.2,254
                c47-46.7,47.3-122.6,0.7-169.6S132.2,37,85.2,83.7c-0.2,0.2-0.5,0.5-0.7,0.7L70.4,70.2C96.6,43.9,132.2,29.2,169.3,29.3z"
                ></path>
              </g>
            </svg>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="610.157"
              height="610.157"
              viewBox="0 0 610.157 610.157"
              className="outer_svg"
            >
              <g
                id="Group_6324"
                data-name="Group 6324"
                transform="translate(-7994.843 -7758.991) rotate(180)"
                opacity="0.2"
              >
                <path
                  id="Ellipse_203"
                  data-name="Ellipse 203"
                  d="M270.079-35C438.3-35,575.157,101.858,575.157,270.079S438.3,575.157,270.079,575.157-35,438.3-35,270.079,101.858-35,270.079-35Zm0,540.158c129.623,0,235.079-105.456,235.079-235.079S399.7,35,270.079,35,35,140.456,35,270.079,140.456,505.157,270.079,505.157Z"
                  transform="translate(-8570 -8334.148)"
                  fill="#8781D3"
                  opacity="0.004"
                ></path>
                <path
                  id="Path_18427"
                  data-name="Path 18427"
                  d="M290.079,595.157C121.858,595.157-15,458.3-15,290.079S121.858-15,290.079-15V55C160.456,55,55,160.456,55,290.079S160.456,525.157,290.079,525.157,525.157,419.7,525.157,290.079h70C595.157,458.3,458.3,595.157,290.079,595.157Z"
                  transform="translate(-8590 -8354.148)"
                  fill="#8781D3"
                ></path>
              </g>
            </svg>
          </div>
        </div>
        {/* second section */}
        <div className="tw-relative tw-bg-prim-bg-gray tw-z-40">
          <div className="tw-py-4 md:tw-w-9/12 tw-m-auto ">
            <div className="tw-py-4 md:tw-w-9/12 tw-m-auto">
              <div className="tw-text-center tw-w-full tw-p-4">
                <h2 className="tw-text-4xl tw-text-gray-500">
                  Over 20 Years of Caring for Communities and the Environment
                </h2>
              </div>
              <div className="tw-text-center tw-w-full tw-p-4 tw-m-auto">
                <p>
                  Since 2002, Providence Whole Care International has worked
                  alongside communities, organizations, and partners across
                  Kenya to provide holistic care that addresses the needs of
                  today while fostering sustainable development for the future.
                  From mental health counseling to environmental advocacy,
                  education, and health programs, we are committed to building a
                  resilient society that thrives in a clean and healthy
                  environment, empowering individuals to lead dignified lives
                  and create lasting change.
                </p>
              </div>
            </div>
            <div className="tw-mt-5 md:tw-mt-10">
              <ProgrammesCards />
            </div>
          </div>
        </div>
      </div>

      {/* Upcoming events */}
      <div className="tw-mb-10 tw-relative">
        {/* Background Image */}
        <div
          className="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-bg-cover tw-bg-center tw-h-1/2"
          style={{ backgroundImage: `url(${eventsBgImage})` }}
        />
        <div className="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-h-1/2 tw-bg-prim-purple tw-opacity-50"></div>

        {/* Content */}
        <div className="md:tw-w-2/3 tw-m-auto ">
          <div className="tw-relative tw-z-10 tw-p-6 ">
            <div className="tw-flex tw-flex-col md:tw-flex-row md:tw-justify-between tw-items-center tw-mb-8">
              <div>
                <p className="tw-text-3xl tw-font-bold">Upcoming Events</p>
              </div>
              <div className="md:tw-mt-0 tw-mt-4">
                <a
                  href={AllEventsPageLink}
                  className="tw-flex tw-flex-row tw-justify-center tw-items-center hover:tw-text-prim-purple tw-text-prim-dark"
                >
                  <p className="tw-mr-2 tw-font-semibold">All events</p>
                  <FaArrowRightLong />
                </a>
              </div>
            </div>
            <div className="md:tw-w-3/5 tw-mb-8">
              <p className="tw-text-gray-600">
                Providence Whole Care International hosts a variety of events
                aimed at empowering communities through education, advocacy, and
                support.
              </p>
            </div>
            <UpcomingEvents events={eventsData} />
          </div>
        </div>
      </div>
    </div>
  );
}
