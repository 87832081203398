import React from 'react'
import PartnershipsImage from "assets/images/backgrounds/partners.jpg";
import KNHLogo from "assets/images/logos/kenyatta-national-logo.png"
import KEMULogo from "assets/images/logos/KeMU-Brand-Logo.png"
import MaishaLogo from "assets/images/logos/maisha-logo.png"
import SwedishEmbassyLogo from "assets/images/logos/swedishembassy.png"
import UKAid from "assets/images/logos/UKaid.svg.png"
import ACT from "assets/images/logos/ACT.png"
import MOH from "assets/images/logos/moh-logo.png"
import Partnershipslider from './partnershipslider';
import { SiTaichigraphics } from "react-icons/si";
import { Accordion } from 'react-bootstrap';
import './partners.css'

export default function Partnerships() {

  const organizations = [
    {
      name: "Kenyatta National Hospital",
      logo: KNHLogo, // Replace with the actual path to the logo
    },
    {
      name: "Kenya Methodist University",
      logo: KEMULogo,
    },
    {
      name: "National AIDs Control Council",
      logo: MaishaLogo,
    },
    {
      name: "Act Change Transform",
      logo: ACT,
    },
    {
      name: "Swedish Embassy",
      logo: SwedishEmbassyLogo,
    },
    {
      name: "UkAid",
      logo: UKAid,
    },

    {
      name: "Ministry of Health",
      logo: MOH,
    },
  ];

  const collaborations = [
    {
      institution: "Kenya Methodist University/Centre of Leadership and Professional Development",
      duration: "2020 - ongoing",
      type: "Mental health and psychosocial wellbeing, climate change training, research, policy and practice",
    },
    {
      institution: "ACT!, UKaid and Swedish Embassy",
      duration: "2014-2016",
      type: "Research, lobbying and advocacy project on solid and electronic waste management.",
    },
    {
      institution: "National AIDs Control Council",
      duration: "2004 - ongoing",
      type: "Financial support for KADREP, and TOWA 1,3,4 project in Nairobi, Kiambu, and Kajiado North; Monitoring and Evaluation; Conferences, Training and workshops",
    },
    {
      institution: "Kenyatta National Hospital VCT/APHIA 2 - Pathfinder/USAID",
      duration: "2008-2011",
      type: "Deployed a counselor; Couple counseling training for counselors; Financial support for community activities; Quality control and quality assurance with support from National Laboratory",
    },
    {
      institution: "Ministry of Health",
      duration: "2004 - ongoing",
      type: "Licensing the medical center; Monitoring and Evaluation; Training - counseling supervision; Kits supply",
    },
    {
      institution: "Kwality Rural Urban Environment Ltd",
      duration: "2010 - ongoing",
      type: "Consultancy Services: Nairobi river drainage system analysis with collaboration NASCOSTI, KEMU, NETFUND, and NEMA; Policy writing for natural resources data and information management policy, 2013 for Department of Resource Surveys and Remote Sensing.",
    },
    {
      institution: "KANCO, KECOFATUMA CBO-KISIMA, WENDI, WEGA, OMOJATRUST Self Help Groups et.al",
      duration: "2007 - ongoing",
      type: "Training, workshops and seminars",
    },
  ];
  

  return (
    <div>
      <div className="tw-relative tw-h-[50vh]">
        <img
          src={PartnershipsImage}
          alt="Background"
          className="tw-object-cover tw-w-full tw-h-full tw-opacity-70"
        />
        <div className="tw-absolute tw-inset-0 tw-bg-black tw-opacity-80" />{" "}
        {/* Overlay */}
        <div className="tw-absolute tw-inset-0 tw-flex md:tw-mx-32 tw-left-10 tw-items-center">
          <div className="md:tw-w-2/3">
            <h5 className="tw-text-white tw-text-3xl md:tw-text-6xl tw-font-bold">
              Partnerships & <br /> Networks
            </h5>
          </div>
        </div>
      </div>
      <section className="tw-mb-5 tw-p-10 md:tw-mx-32">
        <div className="tw-relative tw-inline-block tw-mb-10 tw-text-prim-purple">
          <h2 className="tw-text-3xl md:tw-text-4xl tw-font-bold tw-mb-4">
            Our Partnerships
          </h2>
          <div className="tw-absolute tw-left-0 tw-bottom-0 tw-w-1/3 tw-h-1 tw-bg-prim-orange tw-mt-2" />
        </div>

        <p className="tw-text-gray-700 tw-text-lg">
          Providence Whole Care International (PWCI) actively collaborates with
          a diverse range of partners. Through these partnerships, PWCI works to
          foster sustainable development, improve health outcomes, and address
          critical environmental challenges. Together, we drive initiatives in
          mental health, climate change adaptation, policy development,
          community empowerment, and public health. Below are some of our key
          partners, highlighting the collaborative efforts and shared goals
          aimed at creating lasting positive impacts in the communities we
          serve.
        </p>
      </section>

      <div className="tw-shadow-md md:tw-mx-32 tw-p-10 tw-rounded-md tw-bg-[#f6f6f6] tw-m-10">
        <div className="md:tw-grid tw-grid-cols-2 tw-gap-5 md:tw-px-10">
          <div className="tw-flex tw-flex-col tw-justify-between tw-mb-5">
            <div className="tw-flex tw-flex-row tw-items-center">
              <SiTaichigraphics size={40} className=" tw-text-prim-purple" />{" "}
              <div className='tw-flex tw-flex-col tw-ml-2 '>
                <span className="tw-text-xl tw-font-bold">PWCI</span>
                <span className='tw-text-xs tw-font-semibold'>Making an Impact, Creating Change </span>
              </div>
            </div>
            <div>
              <h5 className="tw-text-4xl md:tw-text-6xl tw-font-semibold tw-mb-5 md:tw-mb-10">
                Patnerships and <br /> Networks
              </h5>
              <p className="tw-text-lg">
                Collaboration with a diverse range of partners including
                academic institutions, government bodies, NGOs, and community
                organizations.{" "}
              </p>
            </div>
          </div>
          <Partnershipslider logos={organizations} />
        </div>
      </div>

      <div className="tw-p-4 md:tw-p-0 md:tw-mx-32 tw-mb-10">
        <Accordion defaultActiveKey="0">
          {collaborations.map((item, index) => (
            <Accordion.Item
              eventKey={index.toString()}
              key={index}
              className="tw-mb-2"
            >
              <Accordion.Header className="tw-bg-gray-200 tw-font-bold tw-text-lg">
                {item.institution}
              </Accordion.Header>
              <Accordion.Body className="tw-bg-white tw-text-gray-700 tw-text-lg">
                <p className="tw-mb-2">
                  <strong>Duration:</strong> {item.duration}
                </p>
                <p className="tw-mb-2">
                  <strong>Type of Collaboration:</strong> {item.type}
                </p>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </div>
  );
}
